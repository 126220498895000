@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@font-face {
    font-family: 'SharpGrotesk-Light';
    src:
        local('SharpGrotesk'),
        url('fonts/SharpGrotesk/SharpGrotesk-Light20.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'SharpGrotesk-Medium20';
    src:
        local('SharpGrotesk'),
        url('fonts/SharpGrotesk/SharpGrotesk-Medium20.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'SharpGrotesk-Black20';
    src:
        local('SharpGrotesk'),
        url('fonts/SharpGrotesk/SharpGrotesk-Black20.otf') format('opentype');
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('fonts/Inter/Inter-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-light';
    src: url('fonts/Inter/Inter-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
